export default {
  paymentFailed: 'Payment failed',
  changePaymentMethod: 'Change method',
  cancelPayment: 'Cancel payment',
  cancelOrder: 'Cancel order',
  inProgress: 'Payment in progress',
  receipt: {
    titleOk: 'Thank you',
    subTitle: 'Receipt?',
    newOrder: 'New customer',
    newOrderNoReceipt: 'New customer / No receipt',
    sendEmail: 'Email receipt',
    print: 'Print receipt',
    email: 'Email',
    emailTitle: 'Email receipt',
    printReceiptMerchant: 'Print merchant',
    printReceiptConsumer: 'Print consumer',
  },
  newPayment: 'New payment',
  qrCode: 'QR code',
  scanQrSwish: 'Scan the QR code with Swish',
  tip: {
    title: 'Amount to pay: {amount}',
    amount: 'Amount',
    amountWithTip: 'Amount with tip',
    selectTip: 'Select tip:',
    customTip: 'Custom',
    noTip: 'No tip',
    pay: 'Pay {amount}'
  },
  initFailed: 'Request failed',
  awaitingTip: 'Awaiting tips',
  awaitingTipCancel: 'No tips',
  awaitingTipTimeout: 'Tip timed out',
  verifyIncomplete: 'Payment incomplete',
  verifyTimeout: 'Payment timed out',
  error: {
    printerUnavailable: 'Printer not available',
    receiptUnavailable: 'Receipt cannot be given, try again'
  },
  pending: 'Pending payment',
  awaitingPending: 'Awaiting payment result'
}
