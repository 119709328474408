export default {
  paymentFailed: 'Betalning misslyckades',
  changePaymentMethod: 'Byt betalmedel',
  cancelPayment: 'Avbryt köp',
  cancelOrder: 'Avbryt order',
  inProgress: 'Betalning pågår',
  receipt: {
    titleOk: 'Tack',
    subTitle: 'Kvitto?',
    newOrder: 'Ny kund',
    newOrderNoReceipt: 'Ny kund / Ej kvitto',
    sendEmail: 'E-posta kvittot',
    print: 'Skriv ut kvitto',
    email: 'E-post',
    emailTitle: 'E-posta kvitto',
    printReceiptMerchant: 'Skriv ut handlare',
    printReceiptConsumer: 'Skriv ut konsument',
  },
  newPayment: 'Ny betalning',
  qrCode: 'QR-kod',
  scanQrSwish: 'Scanna QR-koden med Swish',
  tip: {
    title: 'Belopp att betala: {amount}',
    amount: 'Belopp',
    amountWithTip: 'Belopp med dricks',
    selectTip: 'Välj dricks:',
    customTip: 'Annat',
    noTip: 'Ingen dricks',
    pay: 'Betala {amount}'
  },
  initFailed: 'Begäran misslyckades',
  awaitingTip: 'Väntar på dricks',
  awaitingTipCancel: 'Ingen dricks',
  awaitingTipTimeout: 'Dricks timeout',
  verifyIncomplete: 'Betalning inkomplett',
  verifyTimeout: 'Betalning timeout',
  error: {
    printerUnavailable: 'Skrivare ej tillgänglig',
    receiptUnavailable: 'Kvitto kan inte ges, försök igen'
  },
  pending: 'Väntande betalning',
  awaitingPending: 'Väntar på resultat'
}
